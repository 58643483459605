import React, {useState, useEffect} from 'react';

import Layout from '../components/Layout';
import StoresLocation from '../components/StoresLocation';
import SEO from '../components/SEO';
import {Link as LinkGatsby} from "gatsby";
import {Link, Trans, useTranslation, useI18next} from 'gatsby-plugin-react-i18next';
import {graphql, useStaticQuery} from 'gatsby';
import Social from '../components/Social';
import Flickity from 'react-flickity-component';
import Image from "gatsby-image";
import parse from "html-react-parser";
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    isTablet,
    isDesktop
} from "react-device-detect";
import "../assets/styles/flickity.css";
import Button from "@material-ui/core";


const flickityOptions = {
    cellAlign: 'center',
    wrapAround: true,
    pageDots: false,
    // groupCells: 4,
    imagesLoaded: false,
    contain: true,
    autoPlay: 3000,
    arrowShape: 'M51.7,97.7l4.4-4.4c1-1,1-2.7,0-3.7L21.9,55.5H97c1.5,0,2.6-1.2,2.6-2.6v-6.2c0-1.5-1.2-2.6-2.6-2.6H21.9 L56,9.9c1-1,1-2.7,0-3.7l-4.4-4.4c-1-1-2.7-1-3.7,0L1.9,47.9c-1,1-1,2.7,0,3.7l46.1,46.1C49,98.7,50.7,98.7,51.7,97.7z'
}

const IndexPage = ({location,data: {
    slot1Featured,
    slot2Featured,
    slot3Featured,
    wooGallery,
    adFeaturedFresh1,
    adFeaturedFresh2,
    adEvents1,
    adEvents2,
    adBusiness1,
    adBusiness2,
    healthySweetness1,
    healthySweetness2,
    allWpPage,
    allWpLocation
    //allInstaNode
}}) => {

    const {t} = useTranslation();
    const [pageLoaded, setPageLoaded] = useState(true);

    const [IsMobileCheck, setIsMobileCheck] = useState(false);
    const {language} = useI18next();

    // useEffect(() => {
    //     //  Enable Flickity
    //     setTimeout(function () {
    //         setPageLoaded(true);
    //     }, 0);
    // }, []);

    useEffect(() => {

        //  IF MOBILE
        if( isMobile ){
            setIsMobileCheck(true);
        }

    },[]);

    let [flkty, setFlkty] = useState(null);

    useEffect(() => {
        if (flkty) {
            console.log("this ran");
            flkty.on("settle", () => {
                console.log(`current index is ${flkty.selectedIndex}`);
            });
        }
    },[]);

    //  Page Details
    let pageDetails;

    //  Main Slot
    let featuredSlotFeaturedHome;

    //  Main Slot Left
    let featuredSlotFeaturedHomeSmallLeft;

    //  Main Slot Right
    let featuredSlotFeaturedHomeSmallRight;

    //  Main Slot Fresh News 1
    let featuredNewspaperSm1;

    //  Main Slot Fresh News 2
    let featuredNewspaperSm2;

    //  Main Slot Fresh Events 1
    let featuredAdEventSm1;

    //  Main Slot Fresh Events 2
    let featuredAdEventSm2;

    //  Main Slot Fresh Events 1
    let featuredAdBusinessSm1;

    //  Main Slot Fresh Events 2
    let featuredAdBusinessSm2;

    //  Main Slot Fresh Healthy 1
    let featuredAdHealthySm1;

    //  Main Slot Fresh Healthy 2
    let featuredAdHealthySm2;

    //let instaGood = allInstaNode?.nodes?.slice(0,5);

    //  Check language
    if (language !== 'en') {

        pageDetails = allWpPage?.nodes[0]?.pageDetails;

        if (healthySweetness1.edges[0]?.node.hasOwnProperty("viewingCards") && Object.keys(healthySweetness1.edges[0]?.node.viewingCards).length > 0) {
            featuredAdHealthySm1 = {
                url: (healthySweetness1.edges[0].node.viewingCards.externalUrl) ? healthySweetness1.edges[0].node.viewingCards.externalUrl : healthySweetness1.edges[0].node.link,
                external: (healthySweetness1.edges[0].node.viewingCards.externalUrl) ? true : false,
                showBorder: (healthySweetness1.edges[0].node.viewingCards.showBorder === 'Yes') ? true : false,
                image: {
                    fluid: healthySweetness1.edges[0].node.featuredImage?.node?.localFile?.childImageSharp?.fluid,
                    alt: healthySweetness1.edges[0].node.featuredImage?.node?.altText || ``,
                },
                buttonText: healthySweetness1.edges[0].node.viewingCards?.buttonText,
                title: healthySweetness1.edges[0].node?.title,
                smallDescription: healthySweetness1.edges[0].node.reading?.smallDescription,
            };
        }

        if (healthySweetness2.edges[0]?.node.hasOwnProperty("viewingCards") && Object.keys(healthySweetness2.edges[0]?.node.viewingCards).length > 0) {
            featuredAdHealthySm2 = {
                url: (healthySweetness2.edges[0].node.viewingCards.externalUrl) ? healthySweetness2.edges[0].node.viewingCards.externalUrl : healthySweetness2.edges[0].node.link,
                external: (healthySweetness2.edges[0].node.viewingCards.externalUrl) ? true : false,
                showBorder: (healthySweetness2.edges[0].node.viewingCards.showBorder === 'Yes') ? true : false,
                image: {
                    fluid: healthySweetness2.edges[0].node.featuredImage?.node?.localFile?.childImageSharp?.fluid,
                    alt: healthySweetness2.edges[0].node.featuredImage?.node?.altText || ``,
                },
                buttonText: healthySweetness2.edges[0].node.viewingCards?.buttonText,
                title: healthySweetness2.edges[0].node?.title,
                smallDescription: healthySweetness2.edges[0].node.reading?.smallDescription,
            };
        }

        if (adBusiness1.edges[0]?.node.hasOwnProperty("viewingCards") && Object.keys(adBusiness1.edges[0]?.node.viewingCards).length > 0) {
            featuredAdBusinessSm1 = {
                url: (adBusiness1.edges[0].node.viewingCards.externalUrl) ? adBusiness1.edges[0].node.viewingCards.externalUrl : adBusiness1.edges[0].node.link,
                external: (adBusiness1.edges[0].node.viewingCards.externalUrl) ? true : false,
                showBorder: (adBusiness1.edges[0].node.viewingCards.showBorder === 'Yes') ? true : false,
                image: {
                    fluid: adBusiness1.edges[0].node.featuredImage?.node?.localFile?.childImageSharp?.fluid,
                    alt: adBusiness1.edges[0].node.featuredImage?.node?.altText || ``,
                },
                buttonText: adBusiness1.edges[0].node.viewingCards?.buttonText,
                title: adBusiness1.edges[0].node?.title,
                smallDescription: adBusiness1.edges[0].node.reading?.smallDescription,
            };
        }

        if (adBusiness2.edges[0]?.node.hasOwnProperty("viewingCards") && Object.keys(adBusiness2.edges[0]?.node.viewingCards).length > 0) {
            featuredAdBusinessSm2 = {
                url: (adBusiness2.edges[0].node.viewingCards.externalUrl) ? adBusiness2.edges[0].node.viewingCards.externalUrl : adBusiness2.edges[0].node.link,
                external: (adBusiness2.edges[0].node.viewingCards.externalUrl) ? true : false,
                showBorder: (adBusiness2.edges[0].node.viewingCards.showBorder === 'Yes') ? true : false,
                image: {
                    fluid: adBusiness2.edges[0].node.featuredImage?.node?.localFile?.childImageSharp?.fluid,
                    alt: adBusiness2.edges[0].node.featuredImage?.node?.altText || ``,
                },
                buttonText: adBusiness2.edges[0].node.viewingCards?.buttonText,
                title: adBusiness2.edges[0].node?.title,
                smallDescription: adBusiness2.edges[0].node.reading?.smallDescription,
            };
        }

        if (adEvents1.edges[0]?.node.hasOwnProperty("viewingCards") && Object.keys(adEvents1.edges[0]?.node.viewingCards).length > 0) {
            featuredAdEventSm1 = {
                url: (adEvents1.edges[0].node.viewingCards.externalUrl) ? adEvents1.edges[0].node.viewingCards.externalUrl : adEvents1.edges[0].node.link,
                external: (adEvents1.edges[0].node.viewingCards.externalUrl) ? true : false,
                showBorder: (adEvents1.edges[0].node.viewingCards.showBorder === 'Yes') ? true : false,
                image: {
                    fluid: adEvents1.edges[0].node.featuredImage?.node?.localFile?.childImageSharp?.fluid,
                    alt: adEvents1.edges[0].node.featuredImage?.node?.altText || ``,
                },
                buttonText: adEvents1.edges[0].node.viewingCards?.buttonText,
                title: adEvents1.edges[0].node?.title,
                smallDescription: adEvents1.edges[0].node.reading?.smallDescription,
            };
        }

        if (adEvents2.edges[0]?.node.hasOwnProperty("viewingCards") && Object.keys(adEvents2.edges[0]?.node.viewingCards).length > 0) {
            featuredAdEventSm2 = {
                url: (adEvents2.edges[0].node.viewingCards.externalUrl) ? adEvents2.edges[0].node.viewingCards.externalUrl : adEvents2.edges[0].node.link,
                external: (adEvents2.edges[0].node.viewingCards.externalUrl) ? true : false,
                showBorder: (adEvents2.edges[0].node.viewingCards.showBorder === 'Yes') ? true : false,
                image: {
                    fluid: adEvents2.edges[0].node.featuredImage?.node?.localFile?.childImageSharp?.fluid,
                    alt: adEvents2.edges[0].node.featuredImage?.node?.altText || ``,
                },
                buttonText: adEvents2.edges[0].node.viewingCards?.buttonText,
                title: adEvents2.edges[0].node?.title,
                smallDescription: adEvents2.edges[0].node.reading?.smallDescription,
            };
        }

        if (adFeaturedFresh1.edges[0]?.node.hasOwnProperty("viewingCards") && Object.keys(adFeaturedFresh1.edges[0]?.node.viewingCards).length > 0) {
            featuredNewspaperSm1 = {
                url: (adFeaturedFresh1.edges[0].node.viewingCards.externalUrl) ? adFeaturedFresh1.edges[0].node.viewingCards.externalUrl : adFeaturedFresh1.edges[0].node.link,
                external: (adFeaturedFresh1.edges[0].node.viewingCards.externalUrl) ? true : false,
                showBorder: (adFeaturedFresh1.edges[0].node.viewingCards.showBorder === 'Yes') ? true : false,
                image: {
                    fluid: adFeaturedFresh1.edges[0].node.featuredImage?.node?.localFile?.childImageSharp?.fluid,
                    alt: adFeaturedFresh1.edges[0].node.featuredImage?.node?.altText || ``,
                },
                buttonText: adFeaturedFresh1.edges[0].node.viewingCards?.buttonText,
                title: adFeaturedFresh1.edges[0].node?.title,
                smallDescription: adFeaturedFresh1.edges[0].node.reading?.smallDescription,
            };
        }

        if (adFeaturedFresh2.edges[0]?.node.hasOwnProperty("viewingCards") && Object.keys(adFeaturedFresh2.edges[0]?.node.viewingCards).length > 0) {
            featuredNewspaperSm2 = {
                url: (adFeaturedFresh2.edges[0].node.viewingCards.externalUrl) ? adFeaturedFresh2.edges[0].node.viewingCards.externalUrl : adFeaturedFresh2.edges[0].node.link,
                external: (adFeaturedFresh2.edges[0].node.viewingCards.externalUrl) ? true : false,
                showBorder: (adFeaturedFresh2.edges[0].node.viewingCards.showBorder === 'Yes') ? true : false,
                image: {
                    fluid: adFeaturedFresh2.edges[0].node.featuredImage?.node?.localFile?.childImageSharp?.fluid,
                    alt: adFeaturedFresh2.edges[0].node.featuredImage?.node?.altText || ``,
                },
                buttonText: adFeaturedFresh2.edges[0].node.viewingCards?.buttonText,
                title: adFeaturedFresh2.edges[0].node?.title,
                smallDescription: adFeaturedFresh2.edges[0].node.reading?.smallDescription,
            };
        }

        if (slot1Featured.edges[0]?.node.hasOwnProperty("viewingCards") && Object.keys(slot1Featured.edges[0]?.node.viewingCards).length > 0) {
            featuredSlotFeaturedHome = {
                url: (slot1Featured.edges[0].node.viewingCards.externalUrl) ? slot1Featured.edges[0].node.viewingCards.externalUrl : slot1Featured.edges[0].node.link,
                external: (slot1Featured.edges[0].node.viewingCards.externalUrl) ? true : false,
                showBorder: (slot1Featured.edges[0].node.viewingCards.showBorder === 'Yes') ? true : false,
                image: {
                    fluid: slot1Featured.edges[0].node.featuredImage?.node?.localFile?.childImageSharp?.fluid,
                    alt: slot1Featured.edges[0].node.featuredImage?.node?.altText || ``,
                },
                buttonText: slot1Featured.edges[0].node.viewingCards?.buttonText,
                title: slot1Featured.edges[0].node?.title,
                smallDescription: slot1Featured.edges[0].node.reading?.smallDescription,
            };
        }

        if (slot2Featured.edges[0]?.node.hasOwnProperty("viewingCards") && Object.keys(slot2Featured.edges[0].node.viewingCards).length > 0) {
            featuredSlotFeaturedHomeSmallLeft = {
                url: (slot2Featured.edges[0].node.viewingCards.externalUrl) ? slot2Featured.edges[0].node.viewingCards.externalUrl : slot2Featured.edges[0].node.link,
                external: (slot2Featured.edges[0].node.viewingCards.externalUrl) ? true : false,
                showBorder: (slot2Featured.edges[0].node.viewingCards.showBorder === 'Yes') ? true : false,
                image: {
                    fluid: slot2Featured.edges[0].node.featuredImage?.node?.localFile?.childImageSharp?.fluid,
                    alt: slot2Featured.edges[0].node.featuredImage?.node?.altText || ``,
                },
                buttonText: slot2Featured.edges[0].node.viewingCards?.buttonText,
                title: slot2Featured.edges[0].node?.title,
                smallDescription: slot2Featured.edges[0].node.reading?.smallDescription,
            };
        }

        if (slot3Featured.edges[0]?.node.hasOwnProperty("viewingCards") && Object.keys(slot3Featured.edges[0].node.viewingCards).length > 0) {
            featuredSlotFeaturedHomeSmallRight = {
                url: (slot3Featured.edges[0].node.viewingCards.externalUrl) ? slot3Featured.edges[0].node.viewingCards.externalUrl : slot3Featured.edges[0].node.link,
                external: (slot3Featured.edges[0].node.viewingCards.externalUrl) ? true : false,
                showBorder: (slot3Featured.edges[0].node.viewingCards.showBorder === 'Yes') ? true : false,
                image: {
                    fluid: slot3Featured.edges[0].node.featuredImage?.node?.localFile?.childImageSharp?.fluid,
                    alt: slot3Featured.edges[0].node.featuredImage?.node?.altText || ``,
                },
                buttonText: slot3Featured.edges[0].node.viewingCards?.buttonText,
                title: slot3Featured.edges[0].node?.title,
                smallDescription: slot3Featured.edges[0].node.reading?.smallDescription,
            };
        }

    } else {

        pageDetails = allWpPage?.nodes[0]?.translations[0]?.pageDetails;

        if (healthySweetness1.edges[0]?.node.hasOwnProperty("translations") && healthySweetness1.edges[0].node.translations.length > 0) {
            featuredAdHealthySm1 = {
                url: (healthySweetness1.edges[0].node.translations[0].viewingCards.externalUrl) ? healthySweetness1.edges[0].node.viewingCards.externalUrl : healthySweetness1.edges[0].node.link,
                external: (healthySweetness1.edges[0].node.translations[0].viewingCards.externalUrl) ? true : false,
                showBorder: (healthySweetness1.edges[0].node.translations[0].viewingCards.showBorder === 'Yes') ? true : false,
                image: {
                    fluid: healthySweetness1.edges[0].node.translations[0].featuredImage?.node?.localFile?.childImageSharp?.fluid,
                    alt: healthySweetness1.edges[0].node.translations[0].featuredImage?.node?.altText || ``,
                },
                buttonText: healthySweetness1.edges[0].node.translations[0].viewingCards?.buttonText,
                title: healthySweetness1.edges[0].node.translations[0]?.title,
                smallDescription: healthySweetness1.edges[0].node.translations[0].reading?.smallDescription,
            };
        }

        if (healthySweetness2.edges[0]?.node.hasOwnProperty("translations") && healthySweetness2.edges[0].node.translations.length > 0) {
            featuredAdHealthySm2 = {
                url: (healthySweetness2.edges[0].node.translations[0].viewingCards.externalUrl) ? healthySweetness2.edges[0].node.viewingCards.externalUrl : healthySweetness2.edges[0].node.link,
                external: (healthySweetness2.edges[0].node.translations[0].viewingCards.externalUrl) ? true : false,
                showBorder: (healthySweetness2.edges[0].node.translations[0].viewingCards.showBorder === 'Yes') ? true : false,
                image: {
                    fluid: healthySweetness2.edges[0].node.translations[0].featuredImage?.node?.localFile?.childImageSharp?.fluid,
                    alt: healthySweetness2.edges[0].node.translations[0].featuredImage?.node?.altText || ``,
                },
                buttonText: healthySweetness2.edges[0].node.translations[0].viewingCards?.buttonText,
                title: healthySweetness2.edges[0].node.translations[0]?.title,
                smallDescription: healthySweetness2.edges[0].node.translations[0].reading?.smallDescription,
            };
        }

        if (adBusiness1.edges[0]?.node.hasOwnProperty("translations") && adBusiness1.edges[0].node.translations.length > 0) {
            featuredAdBusinessSm1 = {
                url: (adBusiness1.edges[0].node.translations[0].viewingCards.externalUrl) ? adBusiness1.edges[0].node.viewingCards.externalUrl : adBusiness1.edges[0].node.link,
                external: (adBusiness1.edges[0].node.translations[0].viewingCards.externalUrl) ? true : false,
                showBorder: (adBusiness1.edges[0].node.translations[0].viewingCards.showBorder === 'Yes') ? true : false,
                image: {
                    fluid: adBusiness1.edges[0].node.translations[0].featuredImage?.node?.localFile?.childImageSharp?.fluid,
                    alt: adBusiness1.edges[0].node.translations[0].featuredImage?.node?.altText || ``,
                },
                buttonText: adBusiness1.edges[0].node.translations[0].viewingCards?.buttonText,
                title: adBusiness1.edges[0].node.translations[0]?.title,
                smallDescription: adBusiness1.edges[0].node.translations[0].reading?.smallDescription,
            };
        }

        if (adBusiness2.edges[0]?.node.hasOwnProperty("translations") && adBusiness2.edges[0].node.translations.length > 0) {
            featuredAdBusinessSm2 = {
                url: (adBusiness2.edges[0].node.translations[0].viewingCards.externalUrl) ? adBusiness2.edges[0].node.viewingCards.externalUrl : adBusiness2.edges[0].node.link,
                external: (adBusiness2.edges[0].node.translations[0].viewingCards.externalUrl) ? true : false,
                showBorder: (adBusiness2.edges[0].node.translations[0].viewingCards.showBorder === 'Yes') ? true : false,
                image: {
                    fluid: adBusiness2.edges[0].node.translations[0].featuredImage?.node?.localFile?.childImageSharp?.fluid,
                    alt: adBusiness2.edges[0].node.translations[0].featuredImage?.node?.altText || ``,
                },
                buttonText: adBusiness2.edges[0].node.translations[0].viewingCards?.buttonText,
                title: adBusiness2.edges[0].node.translations[0]?.title,
                smallDescription: adBusiness2.edges[0].node.translations[0].reading?.smallDescription,
            };
        }

        if (adEvents1.edges[0]?.node.hasOwnProperty("translations") && adEvents1.edges[0].node.translations.length > 0) {
            featuredAdEventSm1 = {
                url: (adEvents1.edges[0].node.translations[0].viewingCards.externalUrl) ? adEvents1.edges[0].node.viewingCards.externalUrl : adEvents1.edges[0].node.link,
                external: (adEvents1.edges[0].node.translations[0].viewingCards.externalUrl) ? true : false,
                showBorder: (adEvents1.edges[0].node.translations[0].viewingCards.showBorder === 'Yes') ? true : false,
                image: {
                    fluid: adEvents1.edges[0].node.translations[0].featuredImage?.node?.localFile?.childImageSharp?.fluid,
                    alt: adEvents1.edges[0].node.translations[0].featuredImage?.node?.altText || ``,
                },
                buttonText: adEvents1.edges[0].node.translations[0].viewingCards?.buttonText,
                title: adEvents1.edges[0].node.translations[0]?.title,
                smallDescription: adEvents1.edges[0].node.translations[0].reading?.smallDescription,
            };
        }

        if (adEvents2.edges[0]?.node.hasOwnProperty("translations") && adEvents2.edges[0].node.translations.length > 0) {
            featuredAdEventSm2 = {
                url: (adEvents2.edges[0].node.translations[0].viewingCards.externalUrl) ? adEvents2.edges[0].node.viewingCards.externalUrl : adEvents2.edges[0].node.link,
                external: (adEvents2.edges[0].node.translations[0].viewingCards.externalUrl) ? true : false,
                showBorder: (adEvents2.edges[0].node.translations[0].viewingCards.showBorder === 'Yes') ? true : false,
                image: {
                    fluid: adEvents2.edges[0].node.translations[0].featuredImage?.node?.localFile?.childImageSharp?.fluid,
                    alt: adEvents2.edges[0].node.translations[0].featuredImage?.node?.altText || ``,
                },
                buttonText: adEvents2.edges[0].node.translations[0].viewingCards?.buttonText,
                title: adEvents2.edges[0].node.translations[0]?.title,
                smallDescription: adEvents2.edges[0].node.translations[0].reading?.smallDescription,
            };
        }

        if (adFeaturedFresh1.edges[0]?.node.hasOwnProperty("translations") && adFeaturedFresh1.edges[0].node.translations.length > 0) {
            featuredNewspaperSm1 = {
                url: (adFeaturedFresh1.edges[0].node.translations[0]?.viewingCards.externalUrl) ? adFeaturedFresh1.edges[0].node.viewingCards.externalUrl : adFeaturedFresh1.edges[0].node.link,
                external: (adFeaturedFresh1.edges[0].node.translations[0]?.viewingCards.externalUrl) ? true : false,
                showBorder: (adFeaturedFresh1.edges[0].node.translations[0]?.viewingCards.showBorder === 'Yes') ? true : false,
                image: {
                    fluid: adFeaturedFresh1.edges[0].node.translations[0].featuredImage?.node?.localFile?.childImageSharp?.fluid,
                    alt: adFeaturedFresh1.edges[0].node.translations[0].featuredImage?.node?.altText || ``,
                },
                buttonText: adFeaturedFresh1.edges[0].node.translations[0].viewingCards?.buttonText,
                title: adFeaturedFresh1.edges[0].node.translations[0]?.title,
                smallDescription: adFeaturedFresh1.edges[0].node.translations[0].reading?.smallDescription,
            };
        }

        if (adFeaturedFresh2.edges[0]?.node.hasOwnProperty("translations")) {
            featuredNewspaperSm2 = {
                url: (adFeaturedFresh2.edges[0].node.translations[0]?.viewingCards.externalUrl) ? adFeaturedFresh1.edges[0].node.translations[0]?.viewingCards?.externalUrl : adFeaturedFresh1.edges[0].node.translations[0]?.link,
                external: (adFeaturedFresh2.edges[0].node.translations[0]?.viewingCards.externalUrl) ? true : false,
                showBorder: (adFeaturedFresh2.edges[0].node.translations[0]?.viewingCards.showBorder === 'Yes') ? true : false,
                image: {
                    fluid: slot1Featured.edges[0].node.translations[0]?.featuredImage?.node?.localFile?.childImageSharp?.fluid,
                    alt: slot1Featured.edges[0].node.translations[0]?.featuredImage?.node?.altText || ``,
                },
                buttonText: adFeaturedFresh2.edges[0].node.translations[0]?.viewingCards?.buttonText,
                title: adFeaturedFresh2.edges[0].node.translations[0]?.title,
                smallDescription: adFeaturedFresh2.edges[0].node.translations[0]?.reading?.smallDescription,
            };
        }

        if (slot1Featured.edges[0].node.hasOwnProperty("translations") && slot1Featured.edges[0].node.translations.length > 0) {

            featuredSlotFeaturedHome = {
                url: (slot1Featured.edges[0].node.translations[0]?.viewingCards.externalUrl) ? slot1Featured.edges[0].node.translations[0].viewingCards.externalUrl : slot1Featured.edges[0].node.translations[0]?.link,
                external: (slot1Featured.edges[0].node.translations[0]?.viewingCards.externalUrl) ? true : false,
                showBorder: (slot1Featured.edges[0].node.translations[0].showBorder === 'Yes') ? true : false,
                image: {
                    fluid: slot1Featured.edges[0].node.translations[0]?.featuredImage?.node?.localFile?.childImageSharp?.fluid,
                    alt: slot1Featured.edges[0].node.translations[0]?.featuredImage?.node?.altText || ``,
                },
                buttonText: slot1Featured.edges[0].node.translations[0]?.viewingCards?.buttonText,
                title: slot1Featured.edges[0].node.translations[0]?.title,
                smallDescription: slot1Featured.edges[0].node.translations[0]?.reading?.smallDescription,
            };
        }

        if (slot2Featured.edges[0].node.hasOwnProperty("translations") && slot2Featured.edges[0].node.translations.length > 0) {

            featuredSlotFeaturedHomeSmallLeft = {
                url: (slot2Featured.edges[0].node.translations[0]?.viewingCards.externalUrl) ? slot2Featured.edges[0].node.translations[0].viewingCards.externalUrl : slot1Featured.edges[0].node.translations[0]?.link,
                external: (slot2Featured.edges[0].node.translations[0]?.viewingCards.externalUrl) ? true : false,
                showBorder: (slot2Featured.edges[0].node.translations[0].showBorder === 'Yes') ? true : false,
                image: {
                    fluid: slot2Featured.edges[0].node.translations[0]?.featuredImage?.node?.localFile?.childImageSharp?.fluid,
                    alt: slot2Featured.edges[0].node.translations[0]?.featuredImage?.node?.altText || ``,
                },
                buttonText: slot2Featured.edges[0].node.translations[0]?.viewingCards?.buttonText,
                title: slot2Featured.edges[0].node.translations[0]?.reading?.title,
                smallDescription: slot2Featured.edges[0].node.translations[0].reading?.smallDescription,
            };
        }

        if (slot3Featured.edges[0].node.hasOwnProperty("translations") && slot3Featured.edges[0].node.translations.length > 0) {

            featuredSlotFeaturedHomeSmallLeft = {
                url: (slot3Featured.edges[0].node.translations[0]?.viewingCards.externalUrl) ? slot2Featured.edges[0].node.translations[0]?.viewingCards.externalUrl : slot1Featured.edges[0].node.translations[0]?.link,
                external: (slot3Featured.edges[0].node.translations[0]?.viewingCards.externalUrl) ? true : false,
                showBorder: (slot3Featured.edges[0].node.translations[0].showBorder === 'Yes') ? true : false,
                image: {
                    fluid: slot3Featured.edges[0].node.translations[0].featuredImage?.node?.localFile?.childImageSharp?.fluid,
                    alt: slot3Featured.edges[0].node.translations[0].featuredImage?.node?.altText || ``,
                },
                buttonText: slot3Featured.edges[0].node.translations[0]?.viewingCards?.buttonText,
                title: slot3Featured.edges[0].node.translations[0].reading?.title,
                smallDescription: slot3Featured.edges[0].node.translations[0].reading?.smallDescription,
            };
        }

    }

    const myCustomPrev = () =>{
        flkty.previous();
    }


    const myCustomNext = () =>{
        flkty.next();
    }

    return (
        <Layout>

            <SEO title="Home" />

            <div className="body-wrap">

                {/*SOCIAL*/}
                {
                    (isDesktop || isTablet) ?
                    <Social styling={'mb-5 mobileVersion'}/>
                    : <div className={'mb-5'}></div>
                }


                {/* SLIDER */}
                <section className="intro">

                    <div className="container">

                        <div className="row mainGallery">

                            {/* MAIN SLOT 1 */}
                            <div style={(isMobile && !isTablet ) ? { paddingLeft: '0px', paddingRight: '0px' } : {}} className="col-lg-6 col-sm-12">

                                {
                                    (featuredSlotFeaturedHome) &&
                                    <LinkGatsby target={(featuredSlotFeaturedHome.external) ? '_self' : '_self'}
                                                to={featuredSlotFeaturedHome.url}
                                                className={'hover-link fixed-link right-link slidingGalleryMain'}>
                                        <div className="thumbwrap">
                                            {featuredSlotFeaturedHome.image?.fluid && (
                                                <Image
                                                    fluid={featuredSlotFeaturedHome.image.fluid}
                                                    alt={featuredSlotFeaturedHome.image.alt}
                                                    className="img-fluid full"
                                                />
                                            )}
                                            <span className="more">{featuredSlotFeaturedHome.buttonText}</span>
                                        </div>
                                        <h3 style={(isMobile) ? { paddingLeft: '15px', paddingRight: '15px' } : {}}>{featuredSlotFeaturedHome.title.replace(/(<([^>]+)>)/gi, "").substring(0, 100)}</h3>
                                        <p className={'prime'} style={(isMobile) ? { paddingLeft: '15px', paddingRight: '15px' } : {}}>{featuredSlotFeaturedHome?.smallDescription.replace(/(<([^>]+)>)/gi, "").substring(0, 200)}</p>
                                    </LinkGatsby>
                                }

                            </div>

                            {/* SLOT 1 */}
                            <div className={ (IsMobileCheck) ? 'col-6' : 'col'}>

                                {
                                    (featuredSlotFeaturedHomeSmallLeft) &&
                                    <LinkGatsby
                                        target={(featuredSlotFeaturedHomeSmallLeft.external) ? '_self' : '_self'}
                                        to={featuredSlotFeaturedHomeSmallLeft.url}
                                        state={{ prevPath: location.pathname }}
                                        className={(featuredSlotFeaturedHomeSmallLeft.showBorder || isMobile) ? 'hover-link fixed-link slidingGalleryMain' : 'hover-link slidingGalleryMain'}>
                                        <div className="thumbwrap">
                                            {featuredSlotFeaturedHomeSmallLeft.image?.fluid && (
                                                <Image
                                                    fluid={featuredSlotFeaturedHomeSmallLeft?.image.fluid}
                                                    alt={featuredSlotFeaturedHomeSmallLeft?.image.alt}
                                                    className="img-fluid full"
                                                />
                                            )}
                                            <span
                                                className="more mobile">{featuredSlotFeaturedHomeSmallLeft?.buttonText}</span>
                                        </div>
                                        <h3>{featuredSlotFeaturedHomeSmallLeft?.title.replace(/(<([^>]+)>)/gi, "").substring(0, 30)}</h3>
                                        <p>{featuredSlotFeaturedHomeSmallLeft?.smallDescription.replace(/(<([^>]+)>)/gi, "").substring(0, 65)}</p>
                                    </LinkGatsby>
                                }

                            </div>

                            {/* SLOT 2 */}
                            <div className={ (IsMobileCheck) ? 'col-6' : 'col'}>

                                {
                                    (featuredSlotFeaturedHomeSmallRight) &&
                                    <LinkGatsby
                                        target={(featuredSlotFeaturedHomeSmallRight.external) ? '_self' : '_self'}
                                        to={featuredSlotFeaturedHomeSmallRight.url}
                                        state={{ prevPath: location.pathname }}
                                        className={(featuredSlotFeaturedHomeSmallRight.showBorder || isMobile) ? 'hover-link fixed-link slidingGalleryMain' : 'hover-link slidingGalleryMain'}>
                                        <div className="thumbwrap">
                                            {featuredSlotFeaturedHomeSmallRight.image?.fluid && (
                                                <Image
                                                    fluid={featuredSlotFeaturedHomeSmallRight?.image.fluid}
                                                    alt={featuredSlotFeaturedHomeSmallRight?.image.alt}
                                                    className="img-fluid full"
                                                />
                                            )}
                                            <span
                                                className="more mobile">{featuredSlotFeaturedHomeSmallRight?.buttonText}</span>
                                        </div>
                                        <h3>{featuredSlotFeaturedHomeSmallRight?.title.replace(/(<([^>]+)>)/gi, "").substring(0, 30)}</h3>
                                        <p>{featuredSlotFeaturedHomeSmallRight?.smallDescription.replace(/(<([^>]+)>)/gi, "").substring(0, 65)}</p>
                                    </LinkGatsby>
                                }

                            </div>

                        </div>

                        {/* TITLE SLOT */}
                        { (!isMobile) &&
                        <div className="row justify-content-center titleDescription">

                            <div className="col-lg-8 col-sm-12 text-center">

                                <h2>{ pageDetails?.titleH3 }</h2>
                                <p>{ pageDetails?.titleH3Description }</p>

                            </div>

                        </div>
                        }


                    </div>

                </section>

                {(pageLoaded && wooGallery.products) &&
                <section className="slider-wrapper">

                    <div className="container">

                        <div className="row section-title">

                            <h2 >fresh collection</h2>

                            <button onClick={myCustomPrev} class="flickity-button flickity-prev-next-button previous" type="button" aria-label="Previous"><svg class="flickity-button-icon" viewBox="0 0 100 100"><path d="M51.7,97.7l4.4-4.4c1-1,1-2.7,0-3.7L21.9,55.5H97c1.5,0,2.6-1.2,2.6-2.6v-6.2c0-1.5-1.2-2.6-2.6-2.6H21.9 L56,9.9c1-1,1-2.7,0-3.7l-4.4-4.4c-1-1-2.7-1-3.7,0L1.9,47.9c-1,1-1,2.7,0,3.7l46.1,46.1C49,98.7,50.7,98.7,51.7,97.7z" class="arrow"></path></svg></button>
                            <button onClick={myCustomNext} class="flickity-button flickity-prev-next-button next" type="button" aria-label="Next"><svg class="flickity-button-icon" viewBox="0 0 100 100"><path d="M51.7,97.7l4.4-4.4c1-1,1-2.7,0-3.7L21.9,55.5H97c1.5,0,2.6-1.2,2.6-2.6v-6.2c0-1.5-1.2-2.6-2.6-2.6H21.9 L56,9.9c1-1,1-2.7,0-3.7l-4.4-4.4c-1-1-2.7-1-3.7,0L1.9,47.9c-1,1-1,2.7,0,3.7l46.1,46.1C49,98.7,50.7,98.7,51.7,97.7z" class="arrow" transform="translate(100, 100) rotate(180) "></path></svg></button>

                        </div>

                    </div>

                    <div className="container-fluid">

                        <div className="row">

                            <Flickity flickityRef={c => (setFlkty(c))}
                                className={'slider'} // default ''
                                elementType={'div'} // default 'div'
                                options={flickityOptions} // takes flickity options {}
                                disableImagesLoaded={true} // default false
                                reloadOnUpdate // default false
                                static // default false
                            >

                                {wooGallery.products.map((item, index) => {

                                    if(item?.stock_status === 'instock'){
                                        return (
                                            <Link to={item?.permalink} target={'_blank'} className={(featuredSlotFeaturedHomeSmallRight?.showBorder || isMobile) ? 'hover-link fixed-link slide slidingGallery' : 'hover-link slide slidingGallery'}>
                                                <div className="thumbwrap">
                                                    <Image
                                                        fluid={item.images[0]?.localFile?.childImageSharp?.fluid}
                                                        alt={''}
                                                        className="img-fluid full"
                                                    />
                                                    {/*<img src={'/slide.jpg'} alt="" className="img-fluid full"/>*/}
                                                    <span className="more">SHOP ONLINE</span>
                                                </div>
                                                <h3>{item.name}</h3>
                                                <p>{parse(item.short_description.replace(/(<([^>]+)>)/gi, "").substring(0, 70))}</p>
                                            </Link>
                                        );
                                    }

                                })}

                            </Flickity>

                        </div>

                    </div>

                </section>
                }

                {/* FRESH NEWS CARDS */}
                {
                    (featuredNewspaperSm1 && featuredNewspaperSm2) &&
                    <section className="news">

                        <div className="container">

                            <div className="row section-title">

                                <div className="col text-end">

                                    <h2>fresh paper & news</h2>

                                </div>

                            </div>

                            <div className="row">

                                <div className="col-lg-3 col-sm-12">

                                    <LinkGatsby target={(featuredNewspaperSm1?.external) ? '_blank' : '_self'}
                                                to={featuredNewspaperSm1?.url}
                                                state={{ prevPath: location.pathname }}
                                                className={(featuredNewspaperSm1.showBorder || isMobile) ? 'hover-link fixed-link slidingGalleryMain' : 'hover-link  fixed-link slidingGalleryMain'}>
                                        <div className="thumbwrap">
                                            <Image
                                                fluid={featuredNewspaperSm1.image.fluid}
                                                alt={featuredNewspaperSm1.image.alt}
                                                className="img-fluid full"
                                            />
                                            <span className="more">{featuredNewspaperSm1?.buttonText}</span>
                                        </div>
                                        <h3>{featuredNewspaperSm1?.title}</h3>
                                        <p>{featuredNewspaperSm1?.smallDescription.substring(0, 90) + "..."}</p>
                                    </LinkGatsby>

                                </div>

                                <div className="col-lg-9 col-sm-12">

                                    <LinkGatsby target={(featuredNewspaperSm2?.external) ? '_blank' : '_self'}
                                                to={featuredNewspaperSm2?.url}
                                                state={{ prevPath: location.pathname }}
                                                className={(featuredNewspaperSm2.showBorder || isMobile) ? 'hover-link fixed-link slidingGalleryMain' : 'hover-link right-link slidingGalleryMain'}>
                                        <div className="thumbwrap">
                                            <Image
                                                fluid={featuredNewspaperSm2.image.fluid}
                                                alt={featuredNewspaperSm2.image.alt}
                                                className="img-fluid full"
                                            />
                                            <span className="more">{featuredNewspaperSm2?.buttonText}</span>
                                        </div>
                                        <h3>{featuredNewspaperSm2?.title}</h3>
                                        <p>{featuredNewspaperSm2?.smallDescription}</p>
                                    </LinkGatsby>

                                </div>

                            </div>

                        </div>

                    </section>
                }

                {/*<section className="instafeed">*/}

                    {/*<div className="container">*/}

                        {/*<div className="row align-items-center">*/}

                            {/*<div className="col section-title">*/}

                                {/*<h2>fresh on instagram</h2>*/}

                            {/*</div>*/}

                            {/*<div className="col text-end d-none d-lg-block">*/}

                                {/*<ul className="social">*/}
                                    {/*<li>*/}
                                        {/*<a href="https://www.instagram.com/freshpatisserie/?hl=el" target="_blank">*/}
                                            {/*<span className={'instagramIcon'}></span>*/}
                                        {/*</a>*/}
                                    {/*</li>*/}
                                    {/*<li>*/}
                                        {/*<a href="https://www.facebook.com/freshpatisserie.gr/" target="_blank">*/}
                                            {/*<span border="0" className={'facebookIcon'}></span>*/}
                                        {/*</a>*/}
                                    {/*</li>*/}
                                    {/*<li>*/}
                                        {/*<a href="https://www.linkedin.com/company/freshpatisserie/?originalSubdomain=gr" target="_blank">*/}
                                            {/*<span border="0" className={'linkedinIcon'}></span>*/}
                                        {/*</a>*/}
                                    {/*</li>*/}
                                {/*</ul>*/}

                                {/*<p>follow us on social media</p>*/}

                            {/*</div>*/}

                        {/*</div>*/}

                        {/*<div className="row">*/}

                            {/*<div style={{ backgroundImage: instaGood[0]?.localFile?.childImageSharp?.fluid }} className={(instaGood[0]?.mediaType !== 'VIDEO') ? "col-lg-6 col-sm-12 pb-4" : "col-lg-6 col-sm-12"}>*/}

                                {/*<a target="_blank" href={instaGood[0]?.permalink} >*/}
                                    {/*{*/}
                                        {/*(instaGood[0]?.mediaType !== 'VIDEO')*/}
                                            {/*?*/}
                                            {/*<Image*/}
                                                {/*alt={'instgram picture 1'}*/}
                                                {/*fluid={instaGood[0]?.localFile?.childImageSharp?.fluid}*/}
                                                {/*className="img-fluid full"*/}
                                            {/*/>*/}
                                            {/*:*/}
                                            {/*<img style={{height: '100%'}} className="img-fluid full" src={instaGood[0]?.preview} />*/}
                                    {/*}*/}

                                {/*</a>*/}

                            {/*</div>*/}

                            {/*<div className="col">*/}

                                {/*<div className="row mb-4">*/}

                                    {/*<div className="col">*/}

                                        {/*<a target="_blank" href={instaGood[1]?.permalink}>*/}

                                            {/*{*/}
                                                {/*(instaGood[1]?.mediaType !== 'VIDEO')*/}
                                                    {/*?*/}
                                                    {/*<Image*/}
                                                        {/*alt={'instgram picture 2'}*/}
                                                        {/*fluid={instaGood[1]?.localFile?.childImageSharp?.fluid}*/}
                                                        {/*className="img-fluid full"*/}
                                                    {/*/>*/}
                                                    {/*:*/}
                                                    {/*<img style={{height: '100%'}} className="img-fluid full" src={instaGood[1]?.preview} />*/}
                                            {/*}*/}
                                        {/*</a>*/}

                                    {/*</div>*/}

                                    {/*<div className="col">*/}

                                        {/*<a target="_blank" href={instaGood[2]?.permalink}>*/}
                                            {/*{*/}
                                                {/*(instaGood[2]?.mediaType !== 'VIDEO')*/}
                                                    {/*?*/}
                                                    {/*<Image*/}
                                                        {/*alt={'instgram picture 3'}*/}
                                                        {/*fluid={instaGood[2]?.localFile?.childImageSharp?.fluid}*/}
                                                        {/*className="img-fluid full"*/}
                                                    {/*/>*/}
                                                    {/*:*/}
                                                    {/*<img style={{height: '100%'}} className="img-fluid full" src={instaGood[2]?.preview} />*/}
                                            {/*}*/}
                                        {/*</a>*/}

                                    {/*</div>*/}

                                {/*</div>*/}

                                {/*<div className="row">*/}

                                    {/*<div className="col">*/}

                                        {/*<a target="_blank" href={instaGood[3]?.permalink}>*/}
                                            {/*{*/}
                                                {/*(instaGood[3]?.mediaType !== 'VIDEO')*/}
                                                    {/*?*/}
                                                    {/*<Image*/}
                                                        {/*alt={'instgram picture 4'}*/}
                                                        {/*fluid={instaGood[3]?.localFile?.childImageSharp?.fluid}*/}
                                                        {/*className="img-fluid full"*/}
                                                    {/*/>*/}
                                                    {/*:*/}
                                                    {/*<img style={{height: '100%'}} className="img-fluid full" src={instaGood[3]?.preview} />*/}
                                            {/*}*/}
                                        {/*</a>*/}

                                    {/*</div>*/}

                                    {/*<div className="col">*/}

                                        {/*<a target="_blank" href={instaGood[4]?.permalink}>*/}

                                            {/*{*/}
                                                {/*(instaGood[4]?.mediaType !== 'VIDEO')*/}
                                                    {/*?*/}
                                                    {/*<Image*/}
                                                        {/*alt={'instgram picture 5'}*/}
                                                        {/*fluid={instaGood[4]?.localFile?.childImageSharp?.fluid}*/}
                                                        {/*className="img-fluid full"*/}
                                                    {/*/>*/}
                                                    {/*:*/}
                                                    {/*<img style={{height: '100%'}} className="img-fluid full" src={instaGood[4]?.preview} />*/}
                                            {/*}*/}
                                        {/*</a>*/}

                                    {/*</div>*/}

                                {/*</div>*/}

                            {/*</div>*/}

                        {/*</div>*/}

                    {/*</div>*/}

                {/*</section>*/}

                {/*EVENTS CARDS*/}
                {
                    (featuredAdEventSm1 && featuredAdEventSm2) &&
                    <section className="events">

                        <div className="container">

                            <div className="row">

                                <div className="col section-title">

                                    <h2>events</h2>

                                </div>

                            </div>

                            <div className="row">

                                <div className="col-lg-9 col-sm-12">

                                    <LinkGatsby target={(featuredAdEventSm1?.external) ? '_blank' : '_self'}
                                                to={featuredAdEventSm1?.url}
                                                state={{ prevPath: location.pathname }}
                                                className={(featuredAdEventSm1.showBorder || isMobile) ? 'hover-link fixed-link slidingGalleryMain' : 'hover-link  right-link slidingGalleryMain'}>
                                        <div className="thumbwrap">
                                            <Image
                                                fluid={featuredAdEventSm1.image.fluid}
                                                alt={featuredAdEventSm1.image.alt}
                                                className="img-fluid full"
                                            />
                                            <span className="more">{featuredAdEventSm1?.buttonText}</span>
                                        </div>
                                        <h3>{featuredAdEventSm1?.title}</h3>
                                        <p>{featuredAdEventSm1?.smallDescription}</p>
                                    </LinkGatsby>

                                </div>

                                <div className="col-lg-3 col-sm-12">

                                    <LinkGatsby target={(featuredAdEventSm2?.external) ? '_blank' : '_self'}
                                                to={featuredAdEventSm2?.url}
                                                state={{ prevPath: location.pathname }}
                                                className={(featuredAdEventSm2.showBorder || isMobile) ? 'hover-link fixed-link slidingGalleryMain' : 'hover-link fixed-link slidingGalleryMain'}>
                                        <div className="thumbwrap">
                                            <Image
                                                fluid={featuredAdEventSm2.image.fluid}
                                                alt={featuredAdEventSm2.image.alt}
                                                className="img-fluid full"
                                            />
                                            <span className="more">{featuredAdEventSm2?.buttonText}</span>
                                        </div>
                                        <h3>{featuredAdEventSm2?.title}</h3>
                                        <p>{featuredAdEventSm2?.smallDescription.substring(0, 90) + "..."}</p>
                                    </LinkGatsby>

                                </div>

                            </div>

                        </div>

                    </section>

                }

                {
                    (featuredAdBusinessSm1 && featuredAdBusinessSm2) &&
                    <section className="business">

                        <div className="container">

                            <div className="row">

                                <div className="col section-title text-end">

                                    <h2>business</h2>

                                </div>

                            </div>

                            <div className="row">

                                <div className="col-lg-3 col-sm-12">

                                    <LinkGatsby target={(featuredAdBusinessSm1?.external) ? '_blank' : '_self'}
                                                to={featuredAdBusinessSm1?.url}
                                                state={{ prevPath: location.pathname }}
                                                className={(featuredAdBusinessSm1.showBorder || isMobile) ? 'hover-link fixed-link slidingGalleryMain' : 'hover-link fixed-link slidingGalleryMain'}>
                                        <div className="thumbwrap">
                                            <Image
                                                fluid={featuredAdBusinessSm1.image.fluid}
                                                alt={featuredAdBusinessSm1.image.alt}
                                                className="img-fluid full"
                                            />
                                            <span className="more">{featuredAdBusinessSm1?.buttonText}</span>
                                        </div>
                                        <h3>{featuredAdBusinessSm1?.title}</h3>
                                        <p>{featuredAdBusinessSm1?.smallDescription}</p>
                                    </LinkGatsby>

                                </div>

                                <div className="col-lg-9 col-sm-12">

                                    <LinkGatsby target={(featuredAdBusinessSm2?.external) ? '_blank' : '_self'}
                                                to={featuredAdBusinessSm2?.url}
                                                state={{ prevPath: location.pathname }}
                                                className={(featuredAdBusinessSm2.showBorder || isMobile) ? 'hover-link fixed-link slidingGalleryMain' : 'hover-link  right-link slidingGalleryMain'}>
                                        <div className="thumbwrap">
                                            <Image
                                                fluid={featuredAdBusinessSm2.image.fluid}
                                                alt={featuredAdBusinessSm2.image.alt}
                                                className="img-fluid full"
                                            />
                                            <span className="more">{featuredAdBusinessSm2?.buttonText}</span>
                                        </div>
                                        <h3>{featuredAdBusinessSm2?.title}</h3>
                                        <p>{featuredAdBusinessSm2?.smallDescription}</p>
                                    </LinkGatsby>

                                </div>

                            </div>

                        </div>

                    </section>

                }

                {
                    (featuredAdHealthySm1 && featuredAdHealthySm2) &&
                    <section className="business">

                        <div className="container">

                            <div className="row">

                                <div className="col section-title text-end">

                                    <h2>healthy sweetness</h2>

                                </div>

                            </div>

                            <div className="row">

                                <div className="col-lg-3 col-sm-12">

                                    <LinkGatsby target={(featuredAdHealthySm1?.external) ? '_blank' : '_self'}
                                                to={featuredAdHealthySm1?.url}
                                                state={{ prevPath: location.pathname }}
                                                className={(featuredAdHealthySm1.showBorder || isMobile) ? 'hover-link fixed-link slidingGalleryMain' : 'hover-link fixed-link slidingGalleryMain'}>
                                        <div className="thumbwrap">
                                            <Image
                                                fluid={featuredAdHealthySm1.image.fluid}
                                                alt={featuredAdHealthySm1.image.alt}
                                                className="img-fluid full"
                                            />
                                            <span className="more">{featuredAdHealthySm1?.buttonText}</span>
                                        </div>
                                        <h3>{featuredAdHealthySm1?.title}</h3>
                                        <p>{featuredAdHealthySm1?.smallDescription}</p>
                                    </LinkGatsby>

                                </div>

                                <div className="col-lg-9 col-sm-12">

                                    <LinkGatsby target={(featuredAdHealthySm2?.external) ? '_blank' : '_self'}
                                                to={featuredAdHealthySm2?.url}
                                                state={{ prevPath: location.pathname }}
                                                className={(featuredAdHealthySm2.showBorder || isMobile) ? 'hover-link fixed-link slidingGalleryMain' : 'hover-link  right-link slidingGalleryMain'}>
                                        <div className="thumbwrap">
                                            <Image
                                                fluid={featuredAdHealthySm2.image.fluid}
                                                alt={featuredAdHealthySm2.image.alt}
                                                className="img-fluid full"
                                            />
                                            <span className="more">{featuredAdHealthySm2?.buttonText}</span>
                                        </div>
                                        <h3>{featuredAdHealthySm2?.title}</h3>
                                        <p>{featuredAdHealthySm2?.smallDescription}</p>
                                    </LinkGatsby>

                                </div>

                            </div>

                        </div>

                    </section>

                }

                {/* STORES */}
                <StoresLocation location={location} locations={allWpLocation} language={language} />

            </div>

        </Layout>
    );
};

export default IndexPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    },
    allWpLocation {
        nodes {
          locationInformation {
            address
            city
            latitude
            longitude
            telephone
            directions
          }
          translations{
            locationInformation {
              address
              city
              latitude
              longitude
              telephone
              directions
            }
          }
        }
      },
    slot1Featured: allWpPost(
        filter: {tags: {nodes: {elemMatch: {name: {eq: "featured home"}}}}}
        limit: 1
        sort: {fields: [date], order: DESC}
      ) {
        edges {
          node {
            reading {
              minuteRead
              smallDescription
              secondtitle
            }
            viewingCards {
              buttonText
              externalUrl
              showBorder
            }
            link
            title
            language {
              code
            }
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                      fluid(maxWidth: 580, maxHeight: 580, quality: 80) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                }
              }
            }
            tags {
              nodes {
                name
              }
            }
            translations {
            reading {
                minuteRead
                smallDescription
                secondtitle
            }
            viewingCards {
              buttonText
              externalUrl
              showBorder
            }
            link
            title
            language {
              code
            }
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                      fluid(maxWidth: 500, quality: 80, srcSetBreakpoints: [ 400, 500 ]) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                }
              }
            }
            }
          }
        }
      },
      slot2Featured: allWpPost(
        filter: {tags: {nodes: {elemMatch: {name: {eq: "featured home sm1"}}}}}
        limit: 1
        sort: {fields: [date], order: DESC}
      ) {
        edges {
          node {
            reading {
              minuteRead
              smallDescription
              secondtitle
            }
            viewingCards {
              buttonText
              externalUrl
              showBorder
            }
            link
            title
            language {
              code
            }
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                      fluid(maxWidth: 280,maxHeight: 580, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                }
              }
            }
            tags {
              nodes {
                name
              }
            }
            translations {
              reading {
              minuteRead
              smallDescription
              secondtitle
            }
            viewingCards {
              buttonText
              externalUrl
              showBorder
            }
            link
            language {
              code
            }
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                      fluid(maxWidth: 280,maxHeight: 580, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                }
              }
            }
            }
          }
        }
      },
      slot3Featured: allWpPost(
        filter: {tags: {nodes: {elemMatch: {name: {eq: "featured home sm2"}}}}}
        limit: 1
        sort: {fields: [date], order: DESC}
      ) {
        edges {
          node {
            reading {
              minuteRead
              smallDescription
              secondtitle
            }
            viewingCards {
              buttonText
              externalUrl
              showBorder
            }
            link
            title
            language {
              code
            }
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                      fluid(maxWidth: 280,maxHeight: 580, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                }
              }
            }
            tags {
              nodes {
                name
              }
            }
            translations {
              reading {
              minuteRead
              smallDescription
              secondtitle
            }
            viewingCards {
              buttonText
              externalUrl
              showBorder
            }
            link
            language {
              code
            }
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                      fluid(maxWidth: 280,maxHeight: 580, quality: 80) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                }
              }
            }
            }
          }
        }
      },
      wooGallery: wcProductsCategories(wordpress_id: {eq: 520}) {
        name
        slug
        products {
          name
          short_description
          permalink
          images {
            localFile {
              childImageSharp {
                fluid( maxWidth: 280, maxHeight: 420, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          stock_status
        }
      },
      adFeaturedFresh1: allWpPost(
        filter: {tags: {nodes: {elemMatch: {name: {eq: "fresh paper section home n1"}}}}}
        limit: 1
        sort: {fields: [date], order: DESC}
      ) {
        edges {
          node {
            reading {
              minuteRead
              smallDescription
              secondtitle
            }
            viewingCards {
              buttonText
              externalUrl
              showBorder
            }
            link
            title
            language {
              code
            }
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 280,maxHeight: 420, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
            tags {
              nodes {
                name
              }
            }
            translations {
              reading {
                minuteRead
                smallDescription
                secondtitle
              }
              viewingCards {
                buttonText
                externalUrl
                showBorder
              }
              link
              title
              language {
                code
              }
              featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 280,maxHeight: 420, quality: 80, srcSetBreakpoints: [ 280 ]) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
               }
              }
            }
          }
    }
  },
  adFeaturedFresh2: allWpPost(
        filter: {tags: {nodes: {elemMatch: {name: {eq: "fresh paper section home n2"}}}}}
        limit: 1
        sort: {fields: [date], order: DESC}
      ) {
        edges {
          node {
            reading {
              minuteRead
              smallDescription
              secondtitle
            }
            viewingCards {
              buttonText
              externalUrl
              showBorder
            }
            link
            title
            language {
              code
            }
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 880, maxHeight: 420, quality: 80, srcSetBreakpoints: [ 400, 600, 880 ]) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
            tags {
              nodes {
                name
              }
            }
            translations {
              reading {
                minuteRead
                smallDescription
                secondtitle
              }
              viewingCards {
                buttonText
                externalUrl
                showBorder
              }
              link
              title
              language {
                code
              }
              featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 880,maxHeight: 420, quality: 80, srcSetBreakpoints: [ 400, 600, 880 ]) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
               }
              }
            }
          }
    }
  },
  adEvents1: allWpPost(
        filter: {tags: {nodes: {elemMatch: {name: {eq: "fresh events section home n1"}}}}}
        limit: 1
        sort: {fields: [date], order: DESC}
      ) {
        edges {
          node {
            reading {
              minuteRead
              smallDescription
              secondtitle
            }
            viewingCards {
              buttonText
              externalUrl
              showBorder
            }
            link
            title
            language {
              code
            }
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 880,maxHeight: 420, quality: 80, srcSetBreakpoints: [ 400, 600, 880 ]) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
            tags {
              nodes {
                name
              }
            }
            translations {
              reading {
                minuteRead
                smallDescription
                secondtitle
              }
              viewingCards {
                buttonText
                externalUrl
                showBorder
              }
              link
              title
              language {
                code
              }
              featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 880,maxHeight: 420, quality: 80) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
               }
              }
            }
          }
    }
  },
  adEvents2: allWpPost(
        filter: {tags: {nodes: {elemMatch: {name: {eq: "fresh events section home n2"}}}}}
        limit: 1
        sort: {fields: [date], order: DESC}
      ) {
        edges {
          node {
            reading {
              minuteRead
              smallDescription
              secondtitle
            }
            viewingCards {
              buttonText
              externalUrl
              showBorder
            }
            link
            title
            language {
              code
            }
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 280,maxHeight: 420, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
            tags {
              nodes {
                name
              }
            }
            translations {
              reading {
                minuteRead
                smallDescription
                secondtitle
              }
              viewingCards {
                buttonText
                externalUrl
                showBorder
              }
              link
              title
              language {
                code
              }
              featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 280,maxHeight: 420, quality: 80, srcSetBreakpoints: [ 280 ]) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
               }
              }
            }
          }
    }
  },
  adBusiness1: allWpPost(
        filter: {tags: {nodes: {elemMatch: {name: {eq: "fresh business section home n1"}}}}}
        limit: 1
        sort: {fields: [date], order: DESC}
      ) {
        edges {
          node {
            reading {
              minuteRead
              smallDescription
              secondtitle
            }
            viewingCards {
              buttonText
              externalUrl
              showBorder
            }
            link
            title
            language {
              code
            }
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 280,maxHeight: 420, quality: 80, srcSetBreakpoints: [ 280 ]) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
            tags {
              nodes {
                name
              }
            }
            translations {
              reading {
                minuteRead
                smallDescription
                secondtitle
              }
              viewingCards {
                buttonText
                externalUrl
                showBorder
              }
              link
              title
              language {
                code
              }
              featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 280,maxHeight: 420, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
               }
              }
            }
          }
    }
  },
  adBusiness2: allWpPost(
        filter: {tags: {nodes: {elemMatch: {name: {eq: "fresh business section home n2"}}}}}
        limit: 1
        sort: {fields: [date], order: DESC}
      ) {
        edges {
          node {
            reading {
              minuteRead
              smallDescription
              secondtitle
            }
            viewingCards {
              buttonText
              externalUrl
              showBorder
            }
            link
            title
            language {
              code
            }
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 880,maxHeight: 420, quality: 80, srcSetBreakpoints: [ 400, 600, 880 ]) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
            tags {
              nodes {
                name
              }
            }
            translations {
              reading {
                minuteRead
                smallDescription
                secondtitle
              }
              viewingCards {
                buttonText
                externalUrl
                showBorder
              }
              link
              title
              language {
                code
              }
              featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 880,maxHeight: 420, quality: 80, srcSetBreakpoints: [ 400, 600, 880 ]) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
               }
              }
            }
          }
    }
  },
  healthySweetness1: allWpPost(
        filter: {tags: {nodes: {elemMatch: {name: {eq: "featured healthy sweetness home n1"}}}}}
        limit: 1
        sort: {fields: [date], order: DESC}
      ) {
        edges {
          node {
            reading {
              minuteRead
              smallDescription
              secondtitle
            }
            viewingCards {
              buttonText
              externalUrl
              showBorder
            }
            link
            title
            language {
              code
            }
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 280,maxHeight: 420, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
            tags {
              nodes {
                name
              }
            }
            translations {
              reading {
                minuteRead
                smallDescription
                secondtitle
              }
              viewingCards {
                buttonText
                externalUrl
                showBorder
              }
              link
              title
              language {
                code
              }
              featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 880,maxHeight: 420, quality: 80, srcSetBreakpoints: [ 400, 600, 880 ]) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
               }
              }
            }
          }
    }
  },
  healthySweetness2: allWpPost(
        filter: {tags: {nodes: {elemMatch: {name: {eq: "featured healthy sweetness home n2"}}}}}
        limit: 1
        sort: {fields: [date], order: DESC}
      ) {
        edges {
          node {
            reading {
              minuteRead
              smallDescription
              secondtitle
            }
            viewingCards {
              buttonText
              externalUrl
              showBorder
            }
            link
            title
            language {
              code
            }
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 880,maxHeight: 420, quality: 80, srcSetBreakpoints: [ 400, 600, 880 ]) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
            tags {
              nodes {
                name
              }
            }
            translations {
              reading {
                minuteRead
                smallDescription
                secondtitle
              }
              viewingCards {
                buttonText
                externalUrl
                showBorder
              }
              link
              title
              language {
                code
              }
              featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 880,maxHeight: 420, quality: 80, srcSetBreakpoints: [ 400, 600, 880 ]) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
               }
              }
            }
          }
    }
  },
  allWpPage(filter: {databaseId: {eq: 453}}) {
        nodes {
          databaseId
          title
          pageDetails {
            title
            titleH3
            titleH3Description
            titleSection
            archiveShowMorePosts
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800, quality: 80, srcSetBreakpoints: [ 400, 600, 800 ]) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
          translations {
            pageDetails {
              title
              titleH3
              titleSection
              titleH3Description
              archiveShowMorePosts
            }
          }
        }
      }
  }
`;
